<template>
    <div class="Network full-height pa-4 d-flex flex-column ">
        <div class="d-flex full-height-footerButtons">
            <v-form ref="form">
                <div class="content-area pa-5 flex-grow-1 bg-white">
                    <v-row class="d-flex align-center">
                        <v-col class="col-2">
                            <p>{{ $t('general.status') }}</p>
                        </v-col>
                        <v-col class="col-10 status d-flex align-center" :class="{'connected': network.connected}">
                            <p v-if="network.connected">{{ $t('system.network.connected') }}</p>
                            <p v-else>{{ $t('system.network.noConnection') }}</p>
                        </v-col>
                    </v-row>
                    <v-row class="d-flex align-center">
                        <v-col class="col-2">
                            <switch-on-off name="network-dhcp" :disabled="!checkUserRights('systemNetworkEdit')"
                                           :class="{'active': dhcp}" class="mr-4"/>
                        </v-col>
                        <v-col class="col-10">
                            <p>{{ $t('system.network.dhcp') }}</p>
                        </v-col>
                    </v-row>
                    <v-row class="d-flex align-center">
                        <v-col class="col-2" :class="{'disabled': dhcp}">
                            <p>{{ $t('system.network.hostname') }}</p>
                        </v-col>
                        <v-col class="col-10">
                            <text-input inputName="network-hostname" @onInputFocus="onInputFocus" :disabled="dhcp"
                                        :readonly="!checkUserRights('systemNetworkEdit')"
                                        :class="{'noAction': !checkUserRights('systemNetworkEdit')}"
                                        :rules="[rules.maxLength48]"/>
                        </v-col>
                    </v-row>
                    <v-row class="d-flex align-center">
                        <v-col class="col-2" :class="{'disabled': dhcp}">
                            <p>{{ $t('system.network.address') }}</p>
                        </v-col>
                        <v-col class="col-10">
                            <text-input inputName="network-address" @onInputFocus="onInputFocus" :disabled="dhcp"
                                        onlyNumbers :readonly="!checkUserRights('systemNetworkEdit')"
                                        :class="{'noAction': !checkUserRights('systemNetworkEdit')}"
                                        :rules="[rules.requiredIfNoDHCP, rules.numericDotValue, rules.maxLength24]"/>
                        </v-col>
                    </v-row>
                    <v-row class="d-flex align-center">
                        <v-col class="col-2" :class="{'disabled': dhcp}">
                            <p>{{ $t('system.network.subnet') }}</p>
                        </v-col>
                        <v-col class="col-10">
                            <text-input inputName="network-subnet" @onInputFocus="onInputFocus" :disabled="dhcp"
                                        onlyNumbers :readonly="!checkUserRights('systemNetworkEdit')"
                                        :class="{'noAction': !checkUserRights('systemNetworkEdit')}"
                                        :rules="[rules.requiredIfNoDHCP, rules.numericDotValue, rules.maxLength24]"/>
                        </v-col>
                    </v-row>
                    <v-row class="d-flex align-center">
                        <v-col class="col-2" :class="{'disabled': dhcp}">
                            <p>{{ $t('system.network.gateway') }}</p>
                        </v-col>
                        <v-col class="col-10">
                            <text-input inputName="network-gateway" @onInputFocus="onInputFocus" :disabled="dhcp"
                                        :readonly="!checkUserRights('systemNetworkEdit')"
                                        :class="{'noAction': !checkUserRights('systemNetworkEdit')}"
                                        :rules="[rules.requiredIfNoDHCP, rules.alphanumericDotValue, rules.maxLength24]"/>
                        </v-col>
                    </v-row>
                    <v-row class="d-flex align-center">
                        <v-col class="col-2" :class="{'disabled': dhcp}">
                            <p>{{ $t('system.network.dns') }}</p>
                        </v-col>
                        <v-col class="col-10">
                            <text-input inputName="network-dns" @onInputFocus="onInputFocus" :disabled="dhcp"
                                        :readonly="!checkUserRights('systemNetworkEdit')"
                                        :class="{'noAction': !checkUserRights('systemNetworkEdit')}"
                                        :rules="[rules.maxLength48]"/>
                        </v-col>
                    </v-row>
                </div>
            </v-form>
        </div>
        <v-bottom-navigation grow dark background-color="disiBluegreyLight" class="pt-4" height="76">
            <footer-button></footer-button>
            <footer-button></footer-button>
            <footer-button></footer-button>
            <footer-button></footer-button>
            <footer-button buttonFunction="cancel" @footerButtonClick="goToSystem"></footer-button>
            <footer-button buttonFunction="save" :successCheck="showSaveSuccess" :progress="showSaveProgress"
                           :class="{'disabled': saveDisabled || !checkUserRights('systemNetworkEdit')}"
                           @footerButtonClick="save"></footer-button>
        </v-bottom-navigation>
    </div>
</template>

<script>
import FooterButton from '@/components/FooterButton.vue'
import SwitchOnOff from '@/components/SwitchOnOff.vue'
import {mapState} from "vuex";
import TextInput from "@/components/TextInput.vue";
import mixins from "@/mixins/mixins";

export default {
    name: 'Network',
    components: {
        TextInput,
        SwitchOnOff,
        FooterButton,
    },
    props: {},
    data() {
        return {
            saveDisabled: true,
            showSaveProgress: false,
            showSaveSuccess: false,
            original: {},
            rules: {
                requiredIfNoDHCP: v => {
                    if(this.network.dhcp){
                        return true;
                    } else {
                        return !!v || `${this.$t('validation.required')}`;
                    }
                },
                maxLength24: v => {
                    if (v && !this.network.dhcp) {
                        return v.length < 24 || `${this.$t('validation.chooseShorterValue')}`;
                    } else return true;
                },
                maxLength48: v => {
                    if (v && !this.network.dhcp) {
                        return v.length < 48 || `${this.$t('validation.chooseShorterValue')}`;
                    } else return true;
                },
                alphanumericDotValue: v => {
                    if (v && !this.network.dhcp) {
                        let alphanumeric = /^[ A-Za-z0-9.]+$/;
                        return alphanumeric.test(v) || `${this.$t('validation.pleaseEnterValidValue')} ${this.$t('validation.noSpecialChar')}`;
                    } else return true;
                },
                numericDotValue: v => {
                    if (v && !this.network.dhcp) {
                        let numeric = /^[0-9.]+$/;
                        return numeric.test(v) || `${this.$t('validation.pleaseEnterValidValue')}`;
                    } else return true;
                },
            }
        }
    },
    computed: {
        ...mapState([
            'network',
        ]),
        dhcp() {
            return this.$store.state.network.dhcp;
        },
    },
    methods: {
        goToSystem() {
            this.$router.push('system');
        },
        save() {
            this.$refs.form.validate();
            if (this.$refs.form.validate()) {
                this.showSaveProgress = true;
                let Network = {}
                if (this.network.dhcp != this.original.dhcp) {
                    Network.dhcp = this.network.dhcp;
                }
                if (this.network.hostname != this.original.hostname && this.network.hostname != '') {
                    Network.hostname = this.network.hostname;
                }
                if (this.network.dns != this.original.dns && this.network.dns != '') {
                    Network.dns = this.network.dns;
                }
                if (this.network.address != this.original.address && this.network.address != '') {
                    Network.address = this.network.address;
                }
                if (this.network.gateway != this.original.gateway && this.network.gateway != '') {
                    Network.gateway = this.network.gateway;
                }
                if (this.network.subnet != this.original.subnet && this.network.subnet != '') {
                    Network.subnet = this.network.subnet;
                }
                if (Object.keys(Network).length === 0 && Network.constructor === Object) {
                    this.showSaveProgress = false;
                    this.showSaveSuccess = true;
                    this.watcher();
                    setTimeout(() => {
                        this.saveDisabled = true;
                        this.showSaveSuccess = false;
                    }, 800)
                } else {
                    this.$store.dispatch('postAxiosNoSetter', ['admin/network', Network])
                        .then(response => {
                            this.showSaveProgress = false;
                            if (response.status === 200) {
                                this.showSaveSuccess = true;
                                this.watcher();
                                setTimeout(() => {
                                    this.saveDisabled = true;
                                    this.showSaveSuccess = false;
                                }, 800)
                            }
                        })
                }
            }
        },
        watcher() {
            //copy Network to original for comparison
            this.original = JSON.parse(JSON.stringify(this.network));

            let dhcp = this.$watch('$store.state.network.dhcp', function () {
                this.saveDisabled = false;
                stopWatch();
            });

            let hostname = this.$watch('$store.state.network.hostname', function () {
                this.saveDisabled = false;
                stopWatch();
            });

            let dns = this.$watch('$store.state.network.dns', function () {
                this.saveDisabled = false;
                stopWatch();
            });

            let address = this.$watch('$store.state.network.address', function () {
                this.saveDisabled = false;
                stopWatch();
            });

            let gateway = this.$watch('$store.state.network.gateway', function () {
                this.saveDisabled = false;
                stopWatch();
            });

            let subnet = this.$watch('$store.state.network.subnet', function () {
                this.saveDisabled = false;
                stopWatch();
            });

            const stopWatch = function () {
                dhcp();
                hostname();
                dns();
                address();
                gateway();
                subnet();
            }
        },
        // Keyboard functions ------------------ //
        onInputFocus(input, onlyNumbers) {
            this.$emit('onInputFocus', [input.target.id, onlyNumbers])
            this.$vuetify.goTo('#' + input.target.id, {duration: 100, offset: 80, container: ".content-area"});
        },
    },
    mixins: [mixins],
    created() {
        this.$store.dispatch('getAxiosSetter', ['/admin/network', 'network'])
            .then(response => {
                if (response.status === 200) {
                    this.watcher();
                }
            });
    },
    beforeRouteLeave(to, from, next) {
        if (this.saveDisabled) {
            next()
        } else {
            this.$confirm({
                message: this.$t('alerts.unsavedChanges'),
                show: true,
                cancelText: this.$t('general.cancel'),
                continueText: this.$t('general.continue')
            })
                .then(() => {
                    // continue
                    next();
                })
                .catch(() => {
                    // cancel
                });
        }
    },
}
</script>
<style scoped lang="scss">
.status {
    &::before {
        content: "";
        display: inline-block;
        width: 28px;
        height: 28px;
        border-radius: 50%;
        margin-right: 16px;
        margin-bottom: 2px;
        background-color: $disiRed;
    }

    &.connected {
        &::before {
            background-color: $disiIrisblue;
        }
    }
}
</style>